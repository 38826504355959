/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState, Fragment } from "react";
import { SiteLink, useRouter } from "../../components";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { NavBar } from "./navBar";
import { BackButton } from "../backButton/backButton";
import { Hamburger } from "../hamburger/hamburger";
import { CartButton } from "../cartButton/cartButton";
import style from "./topHeaderStyle";

export function TopHeader(props) {
  const [menuOpened, setMenuOpened] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const { pathname } = useRouter();

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (currPos.y > 90) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    },
    [],
    null,
    true
  );
  function toggleMenu(e) {
    setMenuOpened(!menuOpened);
  }
  function onCloseMenu() {
    setMenuOpened(false);
  }
  function backTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  return (
    <Fragment>
      <header
        css={[
          style.top,
          (scrolled || pathname.includes("/order-thankyou")) &&
            style.topScrolled,
        ]}
        className={`${
          scrolled || pathname.includes("/order-thankyou")
            ? "topHeader_style_scrolled"
            : "topHeader_style"
        } `}
        id='top'
      >
        <BackButton />
        <SiteLink
          to='/'
          css={[
            style.logo,
            (scrolled || pathname.includes("/order-thankyou")) &&
              style.logoScrolled,
          ]}
          onClick={backTop}
        >
          <img
            className='masthead_1'
            css={style.logoImg}
            src='/assets/logos/white-xpress-logo.png'
            alt='logo'
          />
          <img
            className='masthead_2'
            css={style.logoImg}
            src='/assets/logos/white-xpress-apparel-logo.png'
            alt='logo'
          />
        </SiteLink>
        <SiteLink
          to='/'
          className='masthead_3'
          css={[
            style.logo,
            (scrolled || pathname.includes("/order-thankyou")) &&
              style.logoScrolled,
          ]}
          onClick={backTop}
        >
          <img
            css={style.logoImg}
            src='/assets/logos/white-xpress-logo-v2.png'
            alt='logo'
          />
          <img
            css={style.logoImg}
            src='/assets/logos/logo-line.png'
            alt='logo'
          />
        </SiteLink>
        <SiteLink
          to='/apparel'
          className='masthead_3 logo2'
          css={[
            style.logo,
            (scrolled || pathname.includes("/order-thankyou")) &&
              style.logoScrolled,
          ]}
          onClick={backTop}
        >
          <img
            css={style.logoImg}
            src='/assets/logos/white-xpress-apparel-logo-v2.png'
            alt='logo'
          />
        </SiteLink>
        <CartButton />
        <Hamburger
          opened={menuOpened}
          toggleMenu={toggleMenu}
          scrolled={scrolled}
        />
        <NavBar open={menuOpened} onCloseMenu={onCloseMenu}></NavBar>
      </header>
    </Fragment>
  );
}
