/** @jsx jsx */
import { jsx } from '@emotion/core';
import utils from '../../../utils';
import { SiteLink } from '../../../components';
import { tileStyle } from './productTileStyle';

export function ProductTile({ product, currency, customStyle = '' }) {  

  const s = tileStyle;
  const imageUrl = product.thumbnailUrl ? utils.site.resourcePath(product.thumbnailUrl) : '/assets/product-empty.png';
  //const imageUrl = "/sites/nxi/media/miscellaneous/basktetball_banner.jpg";
  const productUrl = product.productUrl ? product.productUrl : `/products/${product.productSlug}`;
  
  let productPrice = 'AU : ' + utils.money.formatMoney(product.priceIncTax, 2);
  if(currency === 'NZD' && (product.productObject && product.productObject.nzPrice)){
    productPrice = 'NZ : ' + utils.money.formatMoney(product.productObject.nzPrice, 2);
  }
  return (
    <div css={[customStyle === 'slider' ? s.tileSlider : s.tile]}>
      <SiteLink css={s.tileLink} to={productUrl}>
        <div css={s.prdImage}>
          <div css={s.bg} style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}></div>
        </div>
        <div css={s.name}>
          {product.productName}
        </div>

        <div css={s.price}>
          { 
            product.productStatus !== 0 ? product.productStatusText :
            product.priceTextOveriding ? product.priceTextOveriding : productPrice
            //product.priceTextOveriding ? product.priceTextOveriding : 'AU : ' + utils.money.formatMoney(product.priceIncTax, 2)
            //product.priceTextOveriding ? product.priceTextOveriding : 'AU : ' + utils.money.formatMoney(product.priceIncTax, Number.isInteger(product.priceIncTax) ? 0 : 2)
          }
        </div>

      </SiteLink>
    </div>
  )
}