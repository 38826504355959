import { mq } from "../../../cssInJs";

export default {
  orderDetails: {
    textAlign: "left",
    marginBottom: "5rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    paddingBottom: "1rem",
    marginTop: "115px",
  },
  summary: {},

  fn: mq({
    minWidth: ["7rem", null, "10em"],
    margin: 0,
  }),
  fv: {},

  orderItems: {},
  orderItem: {
    padding: "0.5rem 0",
    margin: "0.5rem 0",
    borderBottom: "solid 1px #ccc",
  },
  total: {
    //marginLeft: 15,
    fontWeight: "bold",
    color: "chocolate",
  },
  taxText: {
    fontSize: "0.9rem",
    marginLeft: "0.1rem",
  },
};
