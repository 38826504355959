/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import cmsUtils from "../../cmsUtils";
import utils from "utils";
import { SiteLink } from "components";
import { getImageBannerStyle } from "./imagePanelStyle";
import { useParallax } from "site/useParallax/useParallax";

export function ImagePanel(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  //console.log(cmsOption)
  //console.log('item', item);
  const heightSet = cmsUtils.payload(item, "HeightSet") || "standard";
  const photoCredit = cmsUtils.payload(item, "PhotoCredit");
  const photoCreditUrl = cmsUtils.payload(item, "PhotoCreditUrl");
  const hideTop = cmsUtils.payload(item, "HideTop") || "0";
  const hideBottom = cmsUtils.payload(item, "HideBottom") || "0";

  const cssClass = utils.classNames(
    "cms_item",
    "imagePanel",
    "parallax_notused",
    `bannerSize_notused--${heightSet}`,
    item.cssClass || item.anchorName || ""
  );

  const bgUrl =
    cmsOption.pageOptions && cmsOption.pageOptions.imagePath
      ? utils.site.resourcePath(cmsOption.pageOptions.imagePath)
      : utils.site.resourcePath(cmsUtils.payload(item, "ImageUrl"));

  const htmlBlock = React.useRef(null);
  const cssClass2 = utils.classNames(
    "cms_item",
    "htmlPanel",
    cmsOption.isCmsEdit && "html",
    item.cssClass || item.anchorName || ""
  );
  const content = cmsUtils.payload(item, "Content");

  const parallaxSetting = {
    cutoffPadding: 10, //px
    paddingHeightRatio: 1,
    stretchToFit: true,
    actionRange: [0.1, 0.9],
    imageRatio: 1080 / 1920,
    panelHeight: "auto",
    cutTop: hideTop ? parseFloat(hideTop) : 0,
    cutBottom: hideBottom ? parseFloat(hideBottom) : 0,
  };
  //console.log(parallaxSetting)

  const panelRef = React.useRef(null);
  useParallax(panelRef, parallaxSetting);

  const s = getImageBannerStyle(heightSet);

  return (
    <div
      className={cssClass}
      css={s.bannerHeight}
      ref={panelRef}
      style={{ backgroundImage: utils.css.bgUrlStyle(bgUrl) }}
    >
      <div
        ref={htmlBlock}
        className={cssClass2}
        data-cms-html='true'
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>

      {photoCredit && !photoCreditUrl && (
        <div css={s.photoCredit}>{photoCredit}</div>
      )}
      {photoCredit && photoCreditUrl && (
        <SiteLink to={photoCreditUrl} css={s.photoCredit}>
          {photoCredit}
        </SiteLink>
      )}
    </div>
  );
}
