import React from 'react';
import cmsUtils from '../../utils/cmsUtils';
import utils from '../../../utils';
import { ProductItems } from '../../../site/ecm/productList/productItems';
import { ProductItemsClub } from '../../../site/ecm/productList/productItemsClub';
import { Cart } from '../../../site/ecm/cart/cart';

export function EcmItems(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};

  console.log('item', item);

  const cssClass = utils.classNames('cms_item', 'ecm', item.cssClass || item.anchorName || '');

  return cmsOption.isCmsEdit ? <pre style={{ fontSize: '12px', margin: '5px', padding: '10px', backgroundColor: '#eee', border: 'border: 1px solid #ccc;' }}>
    <b>Type: {item?.itemTypeCode}</b>
  </pre> : <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      <ItemBranch item={item} />
    </div>;
}

function ItemBranch({ item }) {
  const typeCode = item?.itemTypeCode;

  if (typeCode === 'Ecm-ShoppingCart') {
    return <Cart />
  }
  else if (typeCode === 'Ecm-ProductList') {
    const categories = cmsUtils.payload(item, 'SelectedCategories');
    console.log('categories', categories);
    return <ProductItems categories={categories} />
  }
  else if (typeCode === 'Ecm-ProductListClub') {
    const categories = cmsUtils.payload(item, 'SelectedCategories');
    console.log('categories', categories);
    return <ProductItemsClub categories={categories} />
  }
  return <div></div>;
}