/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './summaryStyle';

import utils from '../../../utils';
//import { Fragment } from 'react';

export function CartSummary({ cart, currency }) {
  const formatMoney = utils.money.formatMoney;
  let totalAmount = "AU " + formatMoney(cart.totalAmount, 2);
  if(currency === 'NZD' && cart.totalAmountNz){
    totalAmount = "NZD " + formatMoney(cart.totalAmountNz, 2);
  }
  return <div css={style.summary}>
    <div css={style.totalHdr}> Total Amount </div>
    <div css={style.totalAmount}> {totalAmount} </div>
  </div>
}