/** @jsx jsx */
import { jsx } from "@emotion/core";
import style from "./cartButtonStyle";
import { SiteLink } from "../../components";
import { useStore } from "../../store";
import { MdShoppingCart } from "react-icons/md";
//import { useState, Fragment } from 'react';

export function CartButton() {
  const { state } = useStore();

  return (
    <div css={style.container}>
      <SiteLink to={"/cart"} css={style.cartIconC}>
        <div
          className='count-container'
          style={{ position: "relative" }}
          css={style.countContainer}
        >
          <span className='count' css={style.count}>
            {" "}
            {state.cartInfo ? state.cartInfo : 0}{" "}
          </span>
          <MdShoppingCart></MdShoppingCart>
        </div>
      </SiteLink>
    </div>
  );
}
