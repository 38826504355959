//import React from 'react';
import cmsUtils from "../../../cms/utils/cmsUtils";
import utils from "../../../utils";
import { SiteLink } from "../../../components";

/** @jsx jsx */
import { jsx } from "@emotion/core";
// import style from "./style";

export function PageTilesBsyc(props) {
  const item = props.item;
  //const detailHoverBgColor = props.detailHoverBgColor ? props.detailHoverBgColor : "black";
  const cmsOption = props.cmsOption || {};
  const pages = getPages(cmsUtils.payload(item, "PageObjects"));
  const pageTileElts = pages.map((page, index) => (
    <Tile
      page={page}
      key={index}
      disableLink={cmsOption.isCmsEdit}
      idx={index}
    ></Tile>
  ));

  //const cssClass = utils.classNames('cms_item', 'pageTiles tiles', item.cssClass || item.anchorName || '');
  const cssClass = utils.classNames(
    "cms_item",
    "pageTiles__bsyc",
    "pageTiles tiles",
    item.cssClass || item.anchorName || ""
  );

  return (
    <div
      className={cssClass}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
      // css={style.pageTiles}
    >
      {pageTileElts}
    </div>
  );
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function Tile(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;
  return (
    <div className='pageTile tile'>
      <SiteLink
        className='pageTile__content tile__content'
        to={disableLink ? "" : page.pageUrl}
      >
        <div
          className='tile__bg'
          style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}
        ></div>
        <div className='pageTile_details tile__details'>
          <div className='pageTile__title'>{page.pageTitle}</div>
          <div className='pageTile_desc'>
            {page.tileDesc || page.description}
          </div>
          <div className='pageTile_btn'>LEARN MORE</div>
        </div>
      </SiteLink>
    </div>
  );
}
