import React, { useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useRouter } from "./components";
import { StoreProvider } from "./store";
import { ViewPage } from "./cms/pages/viewPage/viewPage";
import { EditPage } from "./cms/pages/editPage/editPage";
import { TopHeader } from "./layout/topHeader/topHeader";
// import { Footer } from './layout/footer/footer';
import { BottomDock } from "./layout/bottomDock/bottomDock";
// import { BlogView } from './site/blog/blogView';
import { AppVersionPage } from "./components";
import { MediaQueryStatus } from "./components";
import { CommonVideoPopup } from "./layout/popup/commonVideoPopup";
import { HelmetProvider } from "react-helmet-async";
//import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { AppRouteChanged } from "./AppRouteChanged";
import AOS from "aos";

//For ECM
import { ProductView } from "./site/ecm/product/productView";
import { OrderDetails } from "./site/ecm/orderDetails/orderDetails";

import env from "./env";
import { useStore } from "./store";

function AppLayout(props) {
  const { pathname } = useRouter();

  //For ECM
  const _useStore = useStore();
  const storeContext = useRef(_useStore);

  let specialClassName = "";

  useEffect(() => {
    if (!localStorage.favorites) {
      localStorage.setItem("favorites", "");
    }

    if (!localStorage.currency) {
      localStorage.setItem("currency", "AUD");
    }

    AOS.init({
      duration: 1200,
      //delay:400,
      //once:true
    });

    // For ECM
    fetch(env.apiBase + "/api/cart/getcart/" + env.getDevCartId())
      .then((res) => res.json())
      .then((res) => {
        const data = res;
        if (data && data.cartInfo) {
          storeContext.current.dispatch({
            type: "SET_STATE",
            payload: {
              cartInfo:
                data.cartInfo.totalQuantity === 0
                  ? 0
                  : data.cartInfo.totalQuantity,
            },
          });
        }
      })
      .catch((error) => {
        storeContext.current.dispatch({
          type: "SET_STATE",
          payload: { cartInfo: 0 },
        });
      });
    storeContext.current.dispatch({
      type: "SET_STATE",
      payload: { currency: localStorage.getItem("currency") },
    });
  }, []);

  return (
    <div
      className={`body-content ${
        pathname === "/" ? "homepage" : "subpage"
      } ${specialClassName}`}
    >
      <AppRouteChanged />
      <TopHeader></TopHeader>
      <div className='main-content'>
        <div className='app-content'>
          <Switch>
            <Route path='/ver' children={<AppVersionPage />} />
            <Route path='/cms/page/render/:id' children={<EditPage />} />
            {/* <Route path="/blog/:id" children={<BlogView />} /> */}

            <Route path='/admin'>
              <Redirect to='/admin' />
            </Route>

            <Route path='/order-thankyou' children={<OrderDetails />} />
            <Route path='/products/:slug' children={<ProductView />} />

            <Route path='/'>
              <ViewPage />
            </Route>
          </Switch>
        </div>
        {/* <app-common-bottom-partial></app-common-bottom-partial> */}
      </div>
      {/* <Footer></Footer> */}
      <BottomDock></BottomDock>
      <MediaQueryStatus />
      <CommonVideoPopup />
    </div>
  );
}

function App() {
  return (
    <StoreProvider>
      <HelmetProvider>
        <Router>
          <AppLayout />
        </Router>
      </HelmetProvider>
    </StoreProvider>
  );
}

export default App;
