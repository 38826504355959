import React from "react";
import cmsUtils from "../../utils/cmsUtils";
import utils from "../../../utils";

export function ImagePanel(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  //console.log(cmsOption)
  //console.log('item', item);
  const heightSet = cmsUtils.payload(item, "HeightSet") || "standard";

  const cssClass = utils.classNames(
    "cms_item",
    "imagePanel",
    "parallax",
    `bannerSize--${heightSet}`,
    item.cssClass || item.anchorName || ""
  );

  const bgUrl =
    cmsOption.pageOptions && cmsOption.pageOptions.imagePath
      ? utils.site.resourcePath(cmsOption.pageOptions.imagePath)
      : utils.site.resourcePath(cmsUtils.payload(item, "ImageUrl"));

  const htmlBlock = React.useRef(null);
  const cssClass2 = utils.classNames(
    "cms_item",
    "htmlPanel",
    cmsOption.isCmsEdit && "html",
    item.cssClass || item.anchorName || ""
  );
  const content = cmsUtils.payload(item, "Content");

  return (
    <div
      className={cssClass}
      data-pxh={heightSet}
      style={{ backgroundImage: utils.css.bgUrlStyle(bgUrl) }}
    >
      <div
        ref={htmlBlock}
        className={cssClass2}
        data-cms-html='true'
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
    </div>
  );
}
