/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './cartStyle';
import React from 'react';
import env from '../../../env';
import { useGet, usePost } from '../../../components';
import { useForm } from '../../../form';
import { Loading, ErrorPopup } from '../../../components';
import { useStep } from './useStep';
import { StepNav } from '../cartStep/stepNav';
import { OrderItems } from '../cartOrderItems/orderItems';
import { getOrderItemFormData } from '../cartOrderItems/orderItemFormData'
import { Shipping } from '../cartShipping/shipping';
import { getShippingFormData } from '../cartShipping/shippingFormData';
import utils from '../../../utils';
import { useStore } from '../../../store';

export function Cart() {
  const [sendingStatus, setSendingStatus] = React.useState(null);
  const [cart, setCart] = React.useState(null);

  const get = useGet();

  React.useEffect(() => {
    get.send(env.apiBase + '/api/cart/getcart/' + env.getDevCartId());
    setSendingStatus('pending')
    // eslint-disable-next-line
  }, []);

  if (get.loading()) {
    return <Loading />
  } else if (get.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={get.errors} />
  }

  if (sendingStatus === 'pending') {
    setCart(get.response);
    setSendingStatus(null);
  }

  if (!cart || cart.cartInfo.totalQuantity === 0){
    return <EmptyCart/>
  }
  return <CartView cart={cart} setCart={setCart} />
}

function CartView({ cart, setCart }) {
  
  const [sendingStatus, setSendingStatus] = React.useState(null);
  const [goNext, setGoNext] = React.useState(false);
  const post = usePost();
  const stepInfo = useStep([
    { id: 'orderItems', title: 'Cart' },
    { id: 'shipping', title: 'Your Details' }
    // { id: 'payment', title: 'Check Out' }
  ])
  const { steps, currentStep, lastStep, moveNext, markAsFilled } = stepInfo;
  //const [promoCodeStatusText, setPromoCodeStatusText] = React.useState();

  const orderItemForm = useForm(getOrderItemFormData(cart), { onControlChanged: orderItemChanged });
  const shippingForm = useForm(getShippingFormData(cart), { onControlChanged: orderItemChanged });

  const { state, dispatch } = useStore();
  
  function orderItemChanged(e, control) {
    
    if (control && control.name === 'quantity') {
      const index = orderItemForm.findControl('items').findIndexById(control.parent.id);
      const orderItem = cart.orderItems[index]
      const value = { qty: control.value, orderItemId: orderItem.orderItemId, cartId: env.getDevCartId() };

      post.send(env.apiBase + "/api/cart/updateqty", value);
      setSendingStatus('pending');
      setGoNext(false);

    }
    
    else if(control && (control.name === 'shippingMethod')){
      shippingForm.findControl("useSameAddress").value = false;

      // const _shippingCountryOption = shippingForm.findControl("shippingCountryOption");
      // _shippingCountryOption.value = '';
      // _shippingCountryOption.validators = _shippingCountryOption.validators.filter(x => x.type !== 'required');

      // const _shippingCountry = shippingForm.findControl("shippingCountry");
      // _shippingCountry.value = '';
      // _shippingCountry.validators = _shippingCountry.validators.filter(x => x.type !== 'required');

      if(control.value === 'Standard'){
        shippingForm.findControl("useSameAddress").value = false;
        //_shippingCountryOption.validators.push(validators.Required());
      }

      const value = { ...shippingForm.getValue(), cartId: env.getDevCartId() };
      post.send(env.apiBase + "/api/cart/saveshippinginfo", value);
      setSendingStatus('pending');
      setGoNext(false);

    }
    // else if(control && control.name === 'shippingCountryOption'){
    //   const _shippingCountry = shippingForm.findControl("shippingCountry");
    //   _shippingCountry.value = '';
    //   _shippingCountry.validators = _shippingCountry.validators.filter(x => x.type !== 'required');
      
    //   if(control.value === 'International'){
    //     _shippingCountry.validators.push(validators.Required());
    //   }
    // }
  }

  function remove(e, index) {
    const orderItem = cart.orderItems[index]
    const value = { orderItemId: orderItem.orderItemId, cartId: env.getDevCartId() };
    post.send(env.apiBase + "/api/cart/removeItem", value);
    setSendingStatus('pending')
    setGoNext(false)
  }

  React.useEffect(() => {
    if (post.done() && sendingStatus === 'pending') {
      const cart = post.response.results.cartModel;
      setSendingStatus(null)
      setCart(cart);
      if (goNext) {
        markAsFilled(currentStep);
        moveNext();
      }
      dispatch({
        type: "SET_STATE",
        payload: {
          cartInfo:
            post.response.results.cartInfo.totalQuantity === 0
              ? 0
              : post.response.results.cartInfo.totalQuantity,
        },
      });
      
      // setPromoCodeStatusText("reset");
      // const promoInfo = post.response.results.promoInfo;
      // if(promoInfo){
      //   let resultText = "";
      //   switch(promoInfo.status){
      //     case 0:
      //       resultText = "Invalid Promo Code";
      //       break;
      //     case 1:
      //       resultText = "Available Promo Code : " + promoInfo.promoEntity.discount + "% Discount Applied";
      //       break;
      //     case 2:
      //       resultText = "Expired Promo Code";  
      //       break;
      //     case 3:
      //         resultText = "";  
      //         break;
      //     default:
      //   }
      //   setPromoCodeStatusText(resultText);
      // }

    }
    // eslint-disable-next-line
  }, [post, currentStep])

  const stepStyle = (index) => {
    const locStyle = index === currentStep ? style.stepCurrent : (
      index === currentStep - 1 ? style.stepPrev : (
        index === currentStep + 1 ? style.stepNext : style.step))
    const movingStyle = index === lastStep ? style.stepLast : null;
    return [locStyle, movingStyle];
  }


  const next = () => {
    markAsFilled(currentStep);
    moveNext();
    utils.ui.scrollTo('#ecmcart');
    //const shippingForm = useForm(getShippingFormData(cart), { onControlChanged: orderItemChanged });
    //shippingForm.reset(getShippingFormData(cart));
  }

  return <div css={style.cart} id="ecmcart">
    <div css={style.stepsWrapper}>
      <StepNav stepInfo={stepInfo} cart = {cart} />
      <div css={style.steps} style={{ transform: `translateX(${-100 * currentStep}%)` }}>
        {steps.map((step, index) => {
          let stepView = null
          switch (step.id) {
            case 'orderItems': stepView = <OrderItems currency = {state.currency} cart={cart}
              form={orderItemForm}
              moveNext={next}
              post={post}
              remove={remove} />; break;
            case 'shipping': stepView = <Shipping currency = {state.currency} cart={cart}
              form={shippingForm}
              post={post} />; break;
            default: break;
          }
          return (
            <div css={stepStyle(index)} key={step.id}>
              {stepView}
              <form id = "stripecheckoutform" style={{display:'none'}} action={`${env.apiBase}/api/cartcheckout/paystripecheckoutforshipping`} method="POST"></form>
            </div>
          )
        })}
      </div>
    </div>
  </div>;
}

function EmptyCart() {
  return <div css={style.cart}>
    <div css={style.stepsWrapper}>
      <h3>Your cart is empty.</h3>
    </div>
  </div>;
}