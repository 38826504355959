import { mq } from '../../cssInJs';

export default{
  club__timer__container : {
    marginTop: '1rem',
    marginBottom: '1rem',
    '.flipdown' : {
      margin: 'auto',
      width:'max-content'
    }
  },
  order__close__text : mq({
    textAlign: 'center',
    marginTop: '1rem',
    marginBottom: '1rem',
    fontWeight: '600',
    fontSize: ['1.1rem',null,'1.5rem']
  })
}