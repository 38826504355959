/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Fragment, useState } from 'react';
//import style from './shippingStyle';
import { Button, ErrorMessage } from '../../../components';
import { CartSummary } from '../cartSummary/summary';
//import utils from '../../../utils';

export function Shipping({ cart, form, post, currency }) {

  const model = form.model;
  const render = (name) => form.renderControl(name);
  const [checkOutButton, setCheckOutButton] = useState("ready");

  console.log("Shipping__Check1");

  const val = form.getValue();
  const billingInfo = model.find('billingInfo');
  const shippingInfo = model.find('shippingInfo');

  const next = (e) => {
    
    form.validateForm(e, () => {
      // saveShipping();
      // utils.ui.scrollTo('#ecmcart');
      console.log("Shipping__Check2");
      setCheckOutButton("pending");
      
      var _value = form.getValue();
      var checkOutForm = document.getElementById("stripecheckoutform");
      checkOutForm.innerHTML = "";
      
      var cartId = document.createElement("input");
      cartId.value = cart.orderId;
      cartId.name = "orderId"; 
      checkOutForm.appendChild(cartId);
      
      // var shippingCountryOption = document.createElement("input");
      // shippingCountryOption.value = _value.shippingCountryOption;
      // shippingCountryOption.name = "shippingCountryOption"; 
      // checkOutForm.appendChild(shippingCountryOption);

      // var shippingCountry = document.createElement("input");
      // shippingCountry.value = _value.shippingCountry;
      // shippingCountry.name = "shippingCountry"; 
      // checkOutForm.appendChild(shippingCountry);

      var usePayWithAfterPay = document.createElement("input");
      usePayWithAfterPay.value = _value.usePayWithAfterPay;
      usePayWithAfterPay.name = "usePayWithAfterPay"; 
      checkOutForm.appendChild(usePayWithAfterPay);

      // var usePayWithAfterPay = document.createElement("input");
      // usePayWithAfterPay.value = _value.usePayWithAfterPay;
      // usePayWithAfterPay.name = "usePayWithAfterPay"; 
      // checkOutForm.appendChild(usePayWithAfterPay);

      var currencyEl = document.createElement("input");
      currencyEl.value = currency;
      currencyEl.name = "currency"; 
      checkOutForm.appendChild(currencyEl);
      
      checkOutForm.submit();

    });

  }
  
  if (billingInfo){
    billingInfo.disableValidators = val.useSameAddress;
  }
  
  shippingInfo.disableValidators = false;

  if(val.shippingMethod === 'Pickup' || val.shippingMethod === '0'){
    shippingInfo.disableValidators = true;
  }

  return <div>
    <div className="row" >
      
      <div className="col-md-9">
        <form>
          <h3>Delivery Method</h3>
          {render('shippingMethod')}
          {
            val.shippingMethod === 'Pickup' &&
            <Fragment>
              {render('usePayWithAfterPay')}
            </Fragment>
          }
        </form>
      </div>

      <div className="col-md-3">
        <CartSummary cart={cart} currency = {currency} />
      </div>

    </div>
    
    {
      val.shippingMethod !== '' &&
      <Fragment>
        <div className="form__actions">
          <Button onClick={next} status={checkOutButton}> Check Out </Button>
          <ErrorMessage errors={form.errors} summaryMessage="Please review the errors." />
          <ErrorMessage errors={post.errors}/>
        </div>
      </Fragment>
    }

  </div>
}

// function Address({form, addressModel}) {
//   const render = (name) => form.renderControl(name, addressModel);
//   return <div>
//     <div className="row">
//       <div className="col-12 col-md-6">
//         {render('firstName')}
//       </div>
//       <div className="col-12 col-md-6">
//         {render('surname')}
//       </div>
//     </div>
//     <div className="row">
//       <div className="col-12">
//         {render('street1')}{render('street2')}
//       </div>
//     </div>
//     <div className="row">
//       <div className="col-12 col-md-4">
//         {render('suburb')}
//       </div>
//       <div className="col-12 col-md-4">
//         {render('state')}
//       </div>
//       <div className="col-12 col-md-4">
//         {render('postcode')}
//       </div>
//     </div>
//     <div className="row">
//       <div className="col-12 col-md-6">
//         {render('email')}
//       </div>
//       <div className="col-12 col-md-6">
//         {render('mobile')}
//       </div>
//     </div>
//   </div>
// }