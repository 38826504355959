/** @jsx jsx */
import { jsx } from '@emotion/core';
//import { useEffect, useState } from 'react';
import utils from 'utils';
//import { useGet, SiteLink } from '../../../../components';
//import env from '../../../../env';
import style from "./fullPanelGoogleMapStyle";
import cmsUtils from '../../cmsUtils';

export function FullPanelGoogleMap(props) {
    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'full__panel__google__map__embed', item.cssClass || item.anchorName || '');
    const code = cmsUtils.payload(item, 'Code');
    
    return (
        <section className={cssClass} css={style.full__panel__google__map__embed} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
            <div css={style.full__panel__google__map__embed__con} className="container full__panel__google__map__embed__con">
                <div dangerouslySetInnerHTML={{ __html: code }}></div>
            </div>
        </section>
    )
}